// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import '~react-image-gallery/styles/scss/image-gallery.scss';

@import '~react-image-gallery/styles/css/image-gallery.css';
// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

body {
  font-family: Inter, Helvetica, sans-serif;
  color: #1a1a1a;
  background: #fff;
}

.image-gallery-svg {
  height: 75px !important;
  width: 15px !important;
  display: none;
}
.image-gallery-icon {
  padding: 200px 40px !important;
  @media (max-width: 1000px) {
    display: none;
  }
}
.image-gallery-slide {
  cursor: auto !important;
}

.hide-scrollbar::-webkit-scrollbar {display:none;}

.MuiSvgIcon-root {
  transform: scale(1);
}
.MuiOutlinedInput-root {
  border-radius: 12px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(124, 177, 66, 0.15) !important;
  border-width: 1px !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(124, 177, 66, 0.3) !important;
  border-width: 1px !important;
}

input, .MuiListItem-button, .MuiOutlinedInput-input {
  font-weight: 600 !important;
  color: #1A1A1A !important;
}


// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
@import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
@import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}
.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

// Aside skins
.kt-aside-dark {
  @import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
  @import './_metronic/_assets/sass/global/layout/aside/skins/light.scss';
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.pac-container {
  z-index: 10000;
}



.MuiPopover-paper{
  border-radius: 20px !important;
  box-shadow: 11px 22px 44px -12px rgba(124, 177, 66, 0.25) !important;
  border: 1px solid rgba(124, 177, 66, 0.15) !important;
  background: linear-gradient(290.46deg, #FBFAF8 0%, #FFFFFF 100%) !important;
}