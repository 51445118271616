.pac-container
    margin-top: 8px
    border-radius: 12px
    border: 1px solid #7CB14255
    box-shadow: none
    font-family: 'Inter'
    font-weight: 600

    .pac-matched
        font-weight: 600
    .pac-item
        padding: 20px
        border: none
        &:hover
            background-color: #F9E7E7
        & >span:last-child
            font-family: 'Inter'
            font-size: 16px !important
            color: black
    .pac-item-query
        font-size: 16px
    .pac-icon
        width: 20px
        background-repeat: no-repeat
        background-position: center
        background-size: 18px 21px
        background-image: url('./pac-icon.svg')
.location-icon,.clear-icon
    position: absolute
    top: 50%
    transform: translateY(-50%)
.location-icon
    left: 20px
.clear-icon
    right: 20px
.pac-container::after
    background-image: none !important
    height: 0px
